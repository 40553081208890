import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-link',
    standalone: true,
    imports: [CommonModule, TranslateModule],
    templateUrl: './link.component.html',
    styleUrls: ['./link.component.scss'],
})
export class LinkComponent {
    @Input() id = '';
    @Input() text = '';
    @Input() hasArrow = false;
    @Input() typeLink = 'dark';
    @Input() fullWidth = false;
    constructor(private readonly translate: TranslateService) {}
}
