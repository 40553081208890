<div class="link" [ngClass]="{ 'full-width': fullWidth }" id="{{ id }}">
    <div [ngClass]="typeLink === 'dark' ? 'dark' : 'light'">
        {{ text | translate }}
    </div>

    <img
        src="../../../../assets/icons/arrow-right.svg"
        alt="{{ 'IMG_ALT.ICON_ARROW_RIGHT' | translate }}"
        [ngClass]="typeLink === 'dark' ? 'arrow-dark' : 'arrow-light'"
        *ngIf="hasArrow"
    />
</div>
